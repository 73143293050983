import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

const RequestComponent = ({ title, description, image }) => {
  return (
    <Box
    bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
    boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside      
    backdropFilter="blur(10px)" // Frosted glass effect

      borderRadius="15px"
      p={6}
      width="12 0%"
      height="100%"
      border="1px solid rgba(255, 255, 255, 0.1)"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Conditionally render image if present with a border around it */}
      {image && (
        <Box 
          // mb={4}
          border="1px solid rgba(255, 255, 255, 0.2)"  // Border around the image
          borderRadius="15px" 
          padding={4}  // Padding for inside the border
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"

        >
          <Image 
            src={image} 
            alt={title} 
            objectFit="contain" 
            width="100%" 
            maxH="150px" 
          />
        </Box>
      )}

      {/* Title */}
      <Box  mt={25}>
      <Text color="white" fontWeight="bold" fontSize="lg" mb={3}>
        {title}
      </Text>

      {/* Description */}
      <Text color="gray.300" fontSize="sm">
        {description}
      </Text>
    </Box>
    </Box>
  );
};

export default RequestComponent;
