import { Box, Flex, Text, Switch, Button } from '@chakra-ui/react';
import React, { useState } from 'react';

const SubscriptionComponent = () => {
  const [isOn, setIsOn] = useState(false); // State to track switch status

  // Function to toggle switch
  const handleToggle = () => setIsOn(!isOn);

  return (
    <Box
    bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
    boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside            
    borderRadius="15px"
      p={5} // Reduced padding to make the card smaller
      width="100%" // Reduced width
      height="400px" // Reduced height
    backdropFilter="blur(10px)" // Frosted glass effect
      border="1px solid rgba(255, 255, 255, 0.1)"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Toggle Switch and Label */}
      <Box 
        border="1px solid rgba(255, 255, 255, 0.2)" 
        padding={3} // Reduced padding to fit the smaller card
        // marginBottom={3}
        minHeight="200px"
        borderRadius={15}
      >
        <Box 
          border="1px solid rgba(255, 255, 255, 0.2)" 
          borderRadius={15}
          minHeight={20}
          padding={3} // Reduced padding
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex 
            align="center" 
            justify="space-between"
            width="100%"
          >
            <Switch
              size="lg"
              isChecked={isOn}
            //   onChange={handleToggle}
              sx={{
                '& .chakra-switch__track': {
                  bg: 'transparent', // Transparent background for the switch
                   border: '1px solid rgba(255, 255, 255, 0.5)',
                  height: '30px',
                  width: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isOn ? 'flex-start' : 'flex-end', // Thumb positioning
                  padding: '0 5px',
                  position: 'relative',
                },
                '& .chakra-switch__thumb': {
                  bg: 'white', // White toggle
                  boxSize: '28px',
                  position: 'relative',
                  transition: 'transform 0.3s ease',
                },
                '& .chakra-switch__track::before': {
                  content: `"${isOn ? 'Off' : 'On'}"`, // Display ON/OFF inside the switch
                  color: 'white',
                  fontWeight: '400',
                  fontSize: '12px',
                  position: 'absolute',
                  left: isOn ? 'auto' : '10px',
                  right: isOn ? '10px' : 'auto',
                },
              }}
            />
            <Text 
              ml={3} 
              fontWeight="1" 
              color="white" // Set color to medium gray
            >
              Subscription
            </Text>
          </Flex>
        </Box>

        {/* Subscription Plans */}
        <Box 
          border="1px solid rgba(255, 255, 255, 0.2)" 
          borderRadius={10}
          padding={3} // Reduced padding for the plans
        >
          <Flex justify="space-between" alignItems="center">
            <Button 
               variant="outline" 
               size="sm" 
               borderColor="rgba(255, 255, 255, 0.5)" // Set border color to medium gray
               _hover={{ bg: 'whiteAlpha.300' }} 
               color="white" // Set text color to medium gray
               borderRadius="2"  
               padding="8px 12px" // Reduced padding for buttons
               fontSize="xs"  // Reduced font size
               fontWeight="1"
               sx={{ flex: 1, margin: '0 5px' }}
            >
              Basic
            </Button>
            <Button 
               variant="outline" 
               size="sm" 
               borderColor="rgba(255, 255, 255, 0.5)" // Set border color to medium gray
               _hover={{ bg: 'whiteAlpha.300' }} 
               color="white" // Set text color to medium gray
               borderRadius="2"  
               padding="8px 12px" // Reduced padding for buttons
               fontSize="xs"  // Reduced font size
               fontWeight={2}
               sx={{ flex: 1, margin: '0 5px' }}
            >
              Pro
            </Button>
            <Button 
              variant="outline" 
              size="sm" 
              borderColor="rgba(255, 255, 255, 0.5)" // Set border color to medium gray
              _hover={{ bg: 'whiteAlpha.300' }} 
              color="white" // Set text color to medium gray
              borderRadius="2"  
              padding="8px 12px" // Reduced padding for buttons
              fontSize="xs"  // Reduced font size
              fontWeight={2}
              sx={{ flex: 1, margin: '0 5px' }}
            >
              Custom
            </Button>
          </Flex>
        </Box>
      </Box>

      {/* Description Section */}
      <Box  mt={25}>
      <Text color="white" fontWeight="bold" fontSize="lg"  mb={3}>
      01. Immediate Insights
      </Text>

      {/* Description */}
      <Text color="gray.300" fontSize="sm">
      Make data-driven decisions NOW, while they are still relevant.
      </Text>
      </Box>
    </Box>
  );
};

export default SubscriptionComponent;
