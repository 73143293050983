import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={5}>
    <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
    Effortless Integration of Diverse Data Types
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Our seamless data integration technology bridges the gap, allowing you to effortlessly consolidate data from multiple sources. Whether it’s customer information from your CRM, logs from web applications, or social media interactions, our solution ensures you have a comprehensive and unified view of your data landscape. Say goodbye to the complexities of traditional ETL processes and hello to streamlined integration that enhances decision-making and drives business growth. </Text>      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
        </Text>
    </Stack>


  </Box>
);

export default SectionBreak;
