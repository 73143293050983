import Cover from "./Cover";
import DecisionMaking from "./DecisionMaking";
import SectionBreak from "./SectionBreak";
import SectionBreak2 from "./SectionBreak2";
import TimelyInsights from "./TimelyInsights";



const Home = () => (
  <>
  <Cover/>
  <SectionBreak/>
  <DecisionMaking/>
  <SectionBreak2/>
  <TimelyInsights/>
</>

);

export default Home;
