import { Box, Text, Button, Stack } from '@chakra-ui/react';
import image from '../../../../assets/images/aiconsulting.png';

const Cover = () => (
  <Box
  width="100vw"
  height="100vh"
  display="flex"
  justifyContent="center"
  alignItems="center"
  padding="20px"
  background="transparent"
  bgImage={image}
  bgSize={{ base: '90%', md: '30%' }} // 90% on smaller screens, 30% on large screens
  bgPosition="center"
  bgRepeat="no-repeat"
  position="relative"
  >
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={1}
    />

    <Stack textAlign="center" spacing={2} zIndex={2}>
      <Text fontSize={{ base: '4xl', md: '7xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
      Real-Time Data Analytics Solutions
      </Text>


    </Stack>
  </Box>
);

export default Cover;
