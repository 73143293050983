import { Box, Text, Button, Stack, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image from '../../assets/images/14.png';

const WhoWeAre = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    alignItems="center"
    padding="50px"
    paddingY="100"
    background="transparent"
    flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens
  >
    {/* Container for the Text */}
    <Stack 
      textAlign="left" 
      spacing={2} 
      zIndex={2} 
      width={{ base: '100%', md: '52%' }} // Full width on small screens, 50% on medium and up
      paddingLeft={{ base: '0', md: '100px' }} // No padding on small screens, padding on larger ones
      mt={{ base: '20px', md: '0' }} // Margin top on small screens for separation
    >
      <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
        Revolutionizing Data Analytics
      </Text>
      <Text fontSize={{ base: 'md', md: 'l' }} color="white" fontFamily="CabinetGrotesk-Regular">
        Incorta is an innovative platform that makes data analysis easier and faster for everyone. Our goal is to change how organizations use data, providing the insights they need right when they need them.
      </Text>

      {/* Use the 'as' prop to render the button as a Link */}
      <Button
        as={Link} // Render as Link
        to="/contact" // Link destination
        mt={5}
        variant="outline"
        borderColor="white"
        color="white"
        width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on larger ones
        maxWidth={400}
        minHeight={50}
        borderRadius={20}
        _hover={{ bg: 'whiteAlpha.200' }}
      >
        Get In Touch
      </Button>
    </Stack>

    {/* Container for the Image */}
    <Box
      width={{ base: '100%', md: '48%' }} // Full width on small screens, 50% on medium and up
      height="auto" // Adjust height automatically
      overflow="hidden" // Ensures no overflow of the image
      borderRadius="md" // Optional: rounded corners
    >
      <Image 
        src={image}
        alt="Background" 
        height={{ base: '30%', md: '90%' }} // Adjust height based on screen size
        width="80%" // Full width of the container
        objectFit="cover" // Ensures the image covers the box without distortion
      />
    </Box>
  </Box>
);

export default WhoWeAre;
