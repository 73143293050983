import { Box, Text, Button, Stack, Image } from '@chakra-ui/react';
import image from '../../assets/images/nlpimage.png';
import { Link } from 'react-router-dom';

const NaturalProcessing = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    alignItems="center"
    padding="50px"
    paddingY="100"
    background="transparent"
    flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens
  >
    {/* Container for the Text */}
    <Stack
      order={{ base: 2, md: 1 }} // Display text first on larger screens, adjust order on smaller screens
      textAlign="left"
      spacing={2}
      zIndex={2}
      width={{ base: '100%', md: '55%' }} // Full width on small screens, 55% on medium and up
      paddingLeft={{ base: '0', md: '50px' }} // No padding on small screens, padding on larger ones
      mt={{ base: '20px', md: '0' }} // Margin top on small screens for separation
    >
      <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
      Comprehensive Security Solutions
      </Text>
      <Text fontSize={{ base: 'md', md: 'l' }} color="white" fontFamily="CabinetGrotesk-Regular">
      Protect your data with our robust security measures. We comply with industry standards and regulations, ensuring that your information is secure and accessible only to authorized users.      </Text>
      <Button
        mt={5}
        variant="outline"
        borderColor="white"
        color="white"
        width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on larger ones
        maxWidth={400}
        minHeight={50}
        borderRadius={20}
        _hover={{ bg: 'whiteAlpha.200' }}
        as={Link} // Use Link from react-router-dom
        to="/comprehensive-security-solutions" // Navigate to the new page
      >
        Learn More About Our Journey
      </Button>
    </Stack>

    {/* Container for the Image */}
    <Box
      order={{ base: 1, md: 2 }} // Image first on small screens, text first on larger screens
      width={{ base: '100%', md: '45%' }} // Full width on small screens, 45% on medium and up
      height="auto" // Adjust height automatically
      overflow="hidden" // Ensures no overflow of the image
      borderRadius="md" // Optional: rounded corners
      display="flex"
      justifyContent="center" // Center image content horizontally
      alignItems="center" // Center image content vertically
    >
      <Image
        src={image}
        alt="Background"
        height={{ base: '30%', md: '90%' }} // Adjust height based on screen size
        width="80%" // Adjust image width
        objectFit="cover" // Ensures the image covers the box without distortion
      />
    </Box>
  </Box>
);

export default NaturalProcessing;

