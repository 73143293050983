import { Box, Text, Stack, Input, Button, Image } from '@chakra-ui/react';
import image from '../../assets/images/Rectangle 19.png';

const GetInTouch = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens, row on larger ones
    justifyContent="center"
    alignItems="flex-start"  // Align to the start for better control of height
    padding="20px"
    bg="gray.100"
    background="transparent"
    position="relative"
  >
    <Box
      width={{ base: '90%', md: '35%', lg: '45%' }}  // Adjust widths for different breakpoints
      bg="rgba(128, 128, 128, 0.2)" // Slightly grayish transparent background
      boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
      borderRadius="20"
      p={{ base: 5, md: 20 }} // Adjust padding for smaller screens
      mr={{ base: 0, md: 5 }}  // Add margin for desktop
      display="flex"            // Set to flex to control child alignment
      flexDirection="column"    // Vertical stacking
      justifyContent="space-between" // Space between elements
      height={{ base: 'auto', md: '730px' }} // Set a longer height for desktop
    >
      <Text fontSize={{ base: '3xl', md: '5xl' }} fontWeight="bold" color="white" mb={0}>
        Get In Touch
      </Text>
      <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="normal" color="white" mb={4}>
        Our team would love to hear from you. Write your message to us!
      </Text>
      <Stack spacing={4} mt={4}> {/* Add spacing between input fields */}
        <Input 
          h="60px"  // Set a specific height (e.g., 60px)       
          borderColor="gray" 
          borderRadius={10} 
          color={'white'}
          placeholder="First Name" 
        />
        <Input 
          h="60px"  // Set a specific height (e.g., 60px)       
          borderColor="gray" 
          borderRadius={10} 
          color={'white'}
          placeholder="Last Name" 
        />
        <Input 
          h="60px"  // Set a specific height (e.g., 60px)       
          borderColor="gray" 
          borderRadius={10} 
          color={'white'}
          placeholder="Email" 
        />
        <Input         
          h="150px"  // Set a specific height (e.g., 150px)       
          borderColor="gray" 
          borderRadius={10} 
          color={'white'}
          placeholder="Message" 
        />
        <Button  
          variant="outline"
          fontWeight="normal" // Set font weight to normal to make text thinner
          h='50px'
          borderRadius={8}
          borderColor="#A9A9A9"
          color="#A9A9A9"
          fontSize="sm" // Reduces the text size inside the button
          _hover={{ bg: 'whiteAlpha.200' }}
          minWidth="150px" // Further reduced minimum width
        >
          Send Message
        </Button>
      </Stack>
    </Box>

    <Box
      width={{ base: '90%', md: '35%', lg: '45%' }}  // Adjust widths for different breakpoints
      height={{ base: 'auto', md: '730px' }} // Match the height of the form
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="20"
      overflow="hidden"  // To ensure that the image does not overflow
      boxShadow="md"
      mt={{ base: 5, md: 0 }} // Add margin on top for mobile view
    >
      <Image
        src={image}  // Replace with the correct path to your image
        alt="AI interaction"
        objectFit="stretch"  // Maintain aspect ratio and cover the area
        width="100%"       // Ensure it takes full width
        height="100%"      // Ensure it takes full height
      />
    </Box>
  </Box>
);

export default GetInTouch;
