import { Box, Text, Stack, Icon, Button } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons
import { Link } from 'react-router-dom';

const TimelyInsights = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
<Stack 
textAlign="center" 
spacing={2} 
// zIndex={2} 
width={{ base: '100%', md: '85%' }} // Full width on small screens, 55% on medium and up
// paddingLeft={{ base: '0', md: '50px' }} // No padding on small screens, padding on larger ones
mt={{ base: '20px', md: '10' }} // Margin top on small screens for separation
alignItems={'center'}
justifyContent={'center'}

>
<Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
Need Timely Insights to Thrive in Today’s Fast-Paced Business World?</Text>
<Button
  mt={5}
  variant="outline"
  borderColor="white"
  color="white"
  width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on larger ones
  maxWidth={400}
  minHeight={50}
  borderRadius={20}
  _hover={{ bg: 'whiteAlpha.200' }}
  as={Link} // Use Link from react-router-dom
  to="/contact" // Navigate to the new page
>
Get in touch with our experts today
</Button>
</Stack>


  </Box>
);

export default TimelyInsights;
