import CommitmentToDeliver from './CommitmentToDeliver';
import Cover from './Cover';
import OurFoundationalValues from './OurFonduationalValues';
import TeamSection from './OurTeam';
import WhoWeAre from './WhoWeAre';

const Home = () => (
  <>
  <Cover/>
  <CommitmentToDeliver/>
  <WhoWeAre/>
  <OurFoundationalValues />
  <TeamSection />

    </>

);

export default Home;
