import React from 'react';
import { Box, Text } from '@chakra-ui/react';

// SectionTitle component that accepts text and position props
const SectionTitle = ({ text, position }) => {
  // Determine the text alignment based on the position prop
  const getAlignment = () => {
    if (position === 'left') return 'flex-start';
    if (position === 'center') return 'center';
    if (position === 'right') return 'flex-end';
    return 'center'; // default is center
  };

  return (
    <Box
      display="flex"
      justifyContent={getAlignment()}  // Align based on position
      pb={5}  // Padding for top and bottom
      px={5}   // Padding for sides
      background="transparent"
      color="white"  // Text color
    >
        <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
        {text}
      </Text>
    </Box>
  );
};

export default SectionTitle;
