import AIConsultation from './AIConsultation';
import AIPoweredAutomation from './AIPoweredAutomation';
import Cover from './Cover';
import HowItWorks from './HowitWorks';
import MLModels from './MLModels';
import NaturalProcessing from './NaturalProcessing';
import WhyChooseUs from './WhyChooseUs';

const Home = () => (
  <>
  <Cover/>
  <WhyChooseUs />
  <AIConsultation />
  <MLModels/>
  <AIPoweredAutomation />
  <NaturalProcessing />
  <HowItWorks />

    </>

);

export default Home;
