import React from 'react';
import { Box, Text, keyframes } from '@chakra-ui/react';

// Animation keyframes for full left-to-right and right-to-left movement
// Use percentage for width-based movement, making it responsive
const moveCapsule = keyframes`
  0% { transform: translateX(0%); }
  50% { transform: translateX(600%); } /* Move to the right end */
  100% { transform: translateX(0%); } /* Move back to the left end */
`;

const CapsuleSlider = ({ label }) => {
  return (
    <Box mb={4}>
      <Text color="gray.300" fontSize="sm" mb={1}>
        {label}
      </Text>
      <Box
        position="relative"
        height="15px" // Height of the capsule track
        width="100%" // Full width for the track
        bg="rgba(255, 255, 255, 0.1)" // Lighter background for the track
        borderRadius="20px" // Capsule-like effect
        overflow="hidden"
      >
        {/* Capsule that moves */}
        <Box
          position="absolute"
          height="15px"
          width={{ base: "30px", md: "50px" }} // Responsive capsule bullet width (adjusts with screen size)
          bgGradient="linear(to-r, darkblue, blue)" // Blue gradient fill color
          borderRadius="20px"
          animation={`${moveCapsule} 8s ease-in-out infinite`} // Adjusted animation speed (8s for slower movement)
          left="0" // Start the capsule at the extreme left
          right="0" // Allow capsule to move to the extreme right
        />
      </Box>
    </Box>
  );
};

const TestAndOptimizeComponent = ({ title, description }) => {
  return (
    <Box
    bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
    boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside      
    
      borderRadius="15px"
      p={6}
      width="100%"
      height="100%"
      backdropFilter="blur(10px)" // Frosted glass effect
      border="1px solid rgba(255, 255, 255, 0.1)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Capsule Sliders */}
      <Box 
        mb={4}
        border="1px solid rgba(255, 255, 255, 0.2)"  // Border around the sliders
        borderRadius="15px"
        padding={4}  // Padding around the sliders
      >
        {/* Three Moving Capsule Bullets */}
        <CapsuleSlider label="Speed" />
        <CapsuleSlider label="Security" />
        <CapsuleSlider label="Accuracy" />
      </Box>

      {/* Title */}
      <Text color="white" fontWeight="bold" fontSize="lg" mb={3}>
        {title}
      </Text>

      {/* Description */}
      <Text color="gray.300" fontSize="sm">
        {description}
      </Text>
    </Box>
  );
};

export default TestAndOptimizeComponent;
