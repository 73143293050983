import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const CapsuleChart = () => {
  const chartData = [80, 70, 60, 50, 40]; // Heights of the bars as percentages

  return (
    <Box
      position="relative"
      height="150px" // Total chart height
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      paddingX={6}
    >
      {/* X-Axis */}
      <Box
        position="absolute"
        bottom="0"
        width="100%"
        height="1px"
        bg="rgba(255, 255, 255, 0.3)" // Light white line for the x-axis
      />

      {/* Capsule Bars */}
      {chartData.map((value, index) => (
        <Box
          key={index}
          height={`${value}%`} // Dynamic bar height based on data
          width="20px" // Fixed width for each bar
          border="1px solid rgba(255, 255, 255, 0.2)" // Border for the capsule effect
          borderRadius="10px" // Capsule-like rounded corners
          mx={3} // Horizontal margin for spacing
          background="transparent" // Transparent inside
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            bottom: '0',
            height: '1px',
            width: '100%',
            bg: 'rgba(255, 255, 255, 0.5)', // Align bar to the x-axis with a small line
          }}
        />
      ))}
    </Box>
  );
};

const IndustryLeaderComponent = ({ title, description }) => {
  return (
    <Box
    bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
    boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside      
      borderRadius="15px"
      p={6}
      width="100%"
      height="100%"
      backdropFilter="blur(10px)" // Frosted glass effect
      border="1px solid rgba(255, 255, 255, 0.1)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Capsule Chart */}
      <Box 
        mb={4}
        border="1px solid rgba(255, 255, 255, 0.2)"  // Border around the chart
        borderRadius="15px"
        padding={4}  // Padding around the chart
        height="200px" // Adjust height of chart container
      >
        <CapsuleChart />
      </Box>

      {/* Title */}
      <Text color="white" fontWeight="bold" fontSize="lg" mb={3}>
        {title}
      </Text>

      {/* Description */}
      <Text color="gray.300" fontSize="sm">
        {description}
      </Text>
    </Box>
  );
};

export default IndustryLeaderComponent;
