import { Box, Text, Stack } from '@chakra-ui/react';
import starImage from '../../assets/images/image.png'; // Adjust the path to your local image

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative" // Allows positioning of the rotating star
  >
    {/* Rotating Star Image */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top="10px"
      right="10px"
      width="30px" // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite" // Animation to rotate the image
      mx={20}
      mb={10}
    />

    <Stack textAlign="center" spacing={2} mt={10}>
      <Text fontSize={{ base: 'xl', md: '4xl' }} fontWeight="bold" color="white">
      We are an extensive AI automation agency that turns businesses into AI-Driven industry leaders.      
      </Text>
    </Stack>

    {/* Adding keyframes for rotation */}
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default SectionBreak;
