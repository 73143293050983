import { Box, Text, Stack, Button } from '@chakra-ui/react';
import starImage from '../../assets/images/image.png';  // Import the star image
import { Link } from 'react-router-dom';

const MeetTeam = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    flexDirection="column"
    alignItems="center"
    padding="50px"
    background="transparent"
    mb={50}
    // bg={'red'}
  >
          {/* <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top={{base: '10px', md: '20px'}}
      right={{base: '10px', md: '50px'}}
      width="30px"  // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite"  // Rotating animation
    /> */}
    {/* Main Card */}
    <Box
      bg="transparent" // Transparent background
      border="1px solid rgba(255, 255, 255, 0.2)" // Subtle border with transparency
      borderRadius={20}
      p={10}
      boxShadow="inset 0 0 15px rgba(255, 255, 255, 0.5)" // Inner glowing effect
      width={{ base: '90%', md: '75%' }} // Responsive width
      minHeight={{ base: 'auto', md: '500px' }} // Minimum height for desktop
      mx="auto" // Center the card
      backdropFilter="blur(10px)" // Glass effect with blur
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      {/* Title */}
      <Text fontSize={{ base: '3xl', md: '5xl' }} fontWeight="bold" color="white" mb={4}>
        Meet Our Exceptional Team
      </Text>

      {/* Description */}
      <Text fontSize={{ base: 'md', md: 'lg' }} color="white" mb={8}>
        Unleash the power of AI-driven creativity and elevate your projects to new heights.
      </Text>

      {/* Button */}
      <Button
        as={Link} // Render as Link
        to="/contact" // Link destination
        variant="outline"
        borderColor="white"
        color="white"
        borderRadius={20}
        _hover={{ bg: 'whiteAlpha.200' }}
        size="lg"
      >
        Get Started Now
      </Button>
    </Box>
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default MeetTeam;
