import React from "react";
import {
  Flex,
  Link,
  Box,
  Button,
  Image,
  useBreakpointValue,
  Stack,
  useDisclosure,
  Collapse,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { NavLink, useLocation } from "react-router-dom";
import image from '../assets/images/Logo.png'

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();

  const activeStyle = {
    color: '#1f4277',
  };

  const direction = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <Box
      as="header"
      position="relative" // Changed to relative to prevent floating
      width="100%"
      top="0"
      zIndex="1000"
      bg='transparent'
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={{ base: 4, md: 6 }} // Responsive padding
        flexDirection={direction}
        py={4}
        flexWrap="wrap" // Allow wrapping on smaller screens
      >
        {/* Logo Section */}
        <Box mb={{base: 5}} mt={{base: 5}}>
          <Image
            src={image} // Path to your logo
            alt="Company Logo"
            height={{ base: '40px', md: '50px' }} // Responsive size for logo
            objectFit="contain"
          />
        </Box>

        <Flex
          display={{ base: "none", md: "flex" }}
          width={{ base: "100%", md: "auto" }} // Responsive width
          background={'transparent'}
          padding="12px 15px"
          justifyContent="center"
          marginY={5}
          borderColor={'gray.700'}
          borderWidth={0.05}
          borderRadius={10}
          flexWrap="wrap" // Allow wrapping of links
        >
          <Link
            as={NavLink}
            to="/"
            style={({ isActive }) => isActive ? activeStyle : { color: 'gray' }}
            margin="0 15px" // Adjusted margin for smaller spacing
          >
            Home
          </Link>
          <Link
            as={NavLink}
            to="/who-we-are"
            style={({ isActive }) => isActive ? activeStyle : { color: 'gray' }}
            margin="0 15px"
          >
            Who We Are
          </Link>
          <Link
            as={NavLink}
            to="/services"
            style={({ isActive }) => isActive ? activeStyle : { color: 'gray' }}
            margin="0 15px"
          >
            Services
          </Link>
          <Link
            as={NavLink}
            to="/team"
            style={({ isActive }) => isActive ? activeStyle : { color: 'gray' }}
            margin="0 15px"
          >
            Team
          </Link>
          <Link
            as={NavLink}
            to="/contact"
            style={({ isActive }) => isActive ? activeStyle : { color: 'gray' }}
            margin="0 15px"
          >
            Contact
          </Link>
        </Flex>

        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onToggle}
          icon={isOpen ? <CloseIcon color="white" /> : <HamburgerIcon color="white" />}
          aria-label="Toggle Navigation"
          variant="outline"
        />
        <Button
          display={{ base: "none", md: "inline-flex" }}
          colorScheme="teal"
          size="sm"
          minH={10}
          minW={40}
          variant="outline"
          borderColor="#A9A9A9"
          color="#A9A9A9"
          _hover={{ bg: 'whiteAlpha.200' }}
          fontSize="sm" // Reduces the text size inside the button
          marginLeft={{ base: 0, md: 3 }} // Added left margin for spacing
        >
          Log In
        </Button>
      </Flex>

      <Collapse in={isOpen}>
        <Stack
          bg={useColorModeValue("white", "gray.700")}
          p={4}
          display={{ base: "flex", md: "none" }}
        >
          <NavLink to="/" style={({ isActive }) => isActive ? activeStyle : { color: 'black' }} onClick={onToggle}>Home</NavLink>
          <NavLink to="/who-we-are" style={({ isActive }) => isActive ? activeStyle : { color: 'black' }} onClick={onToggle}>Who We Are</NavLink>
          <NavLink to="/services" style={({ isActive }) => isActive ? activeStyle : { color: 'black' }} onClick={onToggle}>Services</NavLink>
          <NavLink to="/team" style={({ isActive }) => isActive ? activeStyle : { color: 'black' }} onClick={onToggle}>Team</NavLink>
          <NavLink to="/contact" style={({ isActive }) => isActive ? activeStyle : { color: 'black' }} onClick={onToggle}>Contact</NavLink>
        </Stack>
      </Collapse>
    </Box>
  );
};

export default Header;
