import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={5}>
    <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
    Comprehensive Security Solutions      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Your data is our priority. With our comprehensive security solutions, we prioritize your information’s safety. Our robust measures are designed to safeguard your data against unauthorized access and breaches. We follow industry standards and regulations to ensure that your information is secure, giving you peace of mind while you focus on your business.
</Text>      
<Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
        </Text>
    </Stack>


  </Box>
);

export default SectionBreak;
