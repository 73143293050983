import Cover from './Cover';
import MeetTeam from './MeetOurTeam';
import OurTeam from './OurTeam';
const Home = () => (
  <>
  <Cover/>
  <OurTeam/>
  <MeetTeam />

    </>

);

export default Home;
