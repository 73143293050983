import ComplexProcesses from "./ComplexProcesses";
import Cover from "./Cover";
import DataComplexities from "./DataComplexities";
import SectionBreak from "./SectionBreak";
import SectionBreak2 from "./SectionBreak2";



const Home = () => (
  <>
  <Cover/>
  <SectionBreak/>
  <ComplexProcesses/>
  <SectionBreak2/>
  <DataComplexities/>
</>

);

export default Home;
