import React from 'react';
import { Box, Flex, Text, Input, Button, keyframes } from '@chakra-ui/react';

// Define keyframes for rotating the whole group of circles
const rotateAroundCenter = keyframes`
  0%, 10% { transform: translate(-50%, -50%) rotate(30deg); }
  30%, 40% { transform: translate(-50%, -50%) rotate(150deg); }
  60%, 70% { transform: translate(-50%, -50%) rotate(270deg); }
  90%, 100% { transform: translate(-50%, -50%) rotate(390deg); }
`;

const rotateSelf = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const steeringAnimation = keyframes`
  0%, 10% { transform: translate(-50%, -50%) rotate(0deg); }
  30%, 40% { transform: translate(-50%, -50%) rotate(120deg); }
  60%, 70% { transform: translate(-50%, -50%) rotate(240deg); }
  90%, 100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const JoinWaitlist = () => (
  <Box
    position="relative"
    bgGradient="linear(to-b, black, #101010)" // Darker background gradient
    color="white"
    textAlign="center"
    w="100vw" // Ensure full width
    h="42vh" // Container height
    bg="transparent"
    mt={20}
  >
    {/* Gradient background behind the center icon */}
    <Box
      position="absolute"
      top="9%"
      left="50%"
      transform="translate(-50%, -50%)"
      w="423px" // Adjust the size of the gradient background
      h="306px" // Adjust the size of the gradient background
      borderRadius="full"
      bgGradient="radial(circle, rgba(0, 204, 255, 0.53) 0%, rgba(18, 2, 46, 0) 60%, rgba(0, 204, 255, 0) 100%)"
      zIndex={0} // Behind the center icon
    />

    {/* Centered Icon */}
    <Box
  position="absolute"
  top={['7%', '10%']} // Adjust top position for small screens
  left="50%"
  w={['60px', '85px']} // Width of 60px on small screens, 85px on larger screens
  h={['60px', '85px']} // Height of 60px on small screens, 85px on larger screens
  transform="translate(-50%, -50%)"
  bgGradient="linear(to-br, blue.400, darkblue)"
  borderRadius="full"
  display="flex"
  justifyContent="center"
  alignItems="center"
  zIndex={1} // Above the gradient background and rotating circles
  animation={`${steeringAnimation} 6s ease-in-out infinite`}
>
<Text fontSize={['lg', 'xl']} fontWeight="bold" color="white"> {/* Responsive font size */}
  ✱
</Text>


</Box>


    {/* Container for rotating circles */}
    <Box
      position="absolute"
      top="19%"
      left="50%"
      transform="translate(-50%, -50%)"
      animation={`${rotateAroundCenter} 6s linear infinite`}
      zIndex={10}
    >
      {/* Circles positioned around the center */}
      {[0, 40, 80, 120, 160, 200, 240, 280, 320].map((angle) => {
        const translateValue = 100; // Consistent radius for all circles

        return (
          <Box
            key={angle}
            position="absolute"
            top="50%"
            left="50%"
            transform={`translate(-50%, -50%) rotate(${angle}deg) translate(${translateValue}px) rotate(-${angle}deg)`}
            zIndex={32}
          >
<Box
  w={['18px', '25px']} // Width of 18px on small screens, 25px on larger screens
  h={['18px', '25px']} // Height of 18px on small screens, 25px on larger screens
  borderRadius="full"
  border="2px solid #5f929f99"
  bg="#5f929f99"
  zIndex={32}
  animation={`${rotateSelf} 2s linear infinite`}
/>

          </Box>
        );
      })}
    </Box>

    {/* SVG Curve */}
    <Box
      as="svg"
      position="absolute"
      top={0}
      left={0}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      w="100%"
      h="100%"
      preserveAspectRatio="none"
      zIndex={10}
    >
      <path
        d="M671.287 10H-132V310C-132 320 -124.366 330 -114.951 330L1554.97 330C1564.37 330 1572 320 1572 310V10H768.581C762.506 10 756.892 14.7234 753.841 20.7721L734.833 58.4915C728.29 71.4672 712.034 71.5344 705.414 58.6131L685.967 20.651C682.9 14.6704 677.319 10 671.287 10Z"
        fill="#111111"
        zIndex={1}
      />
      <path
        d="M768.581 10.5H1571.5V310C1571.5 320 1564.03 330 1554.97 330L-114.952 330C-124.026 330 -131.5 320 -131.5 310V10.5H671.287C677.106 10.5 682.528 15.0418 685.522 20.879L704.969 58.8411C708.359 65.459 714.245 68.7881 720.154 68.7637C726.063 68.7392 731.929 65.3618 735.28 58.7166L754.288 20.9972C757.265 15.0929 762.721 10.5 768.581 10.5Z"
        stroke="white"
        strokeOpacity="0.3"
      />
    </Box>

{/* Container for Main Heading and Input Section */}
<Box
  position="relative"
  zIndex={12}
  pt={['6%', '7%']} // Responsive padding top for small screens
  display="flex"
  flexDirection="column"
  alignItems="center" // Center items horizontally
  justifyContent="center" // Center items vertically
  h="100%" // Ensure the Box takes full height for centering
>
  {/* Main Heading Text */}
  <Text
    fontSize={['3xl', '4xl', '5xl', '6xl']} // Responsive font sizes for different breakpoints
    fontWeight="bold"
    mb={4} // Reduced margin-bottom for better alignment
    zIndex={2}
  >
    Join The Waitlist
  </Text>




      {/* Input and Button Section */}
      <Flex
        justify="space-between"
        align="center"
        border="1px solid rgba(255, 255, 255, 0.2)"
        borderRadius="12px"
        width={['90%', '80%', '60%']}
        mx="auto"
        p={2}
        mt={5}
        bg="black"
      >
        <Input
          placeholder="Your Best Email"
          width="100%"
          border="none"
          bg="transparent"
          color="white"
          opacity={1}
          _placeholder={{ color: 'gray' }}
          _focus={{ outline: 'none' }}
          fontSize={['md', 'lg', 'lg']}
        />
        <Button
          color="white"
          variant="outline"
          border="1px solid rgba(255, 255, 255, 0.2)"
          borderRadius="12px"
          _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
          px={8}
          fontWeight="500"
          fontStyle="normal"
        >
          Join Waitlist
        </Button>
      </Flex>
    </Box>
  </Box>
);

export default JoinWaitlist;