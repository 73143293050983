import { Box, Text, Stack, Image } from '@chakra-ui/react';
import image from '../../assets/images/digitalArt.png';
import starImage from '../../assets/images/image.png'; // Adjust the path to your local image

const CommitmentToDeliver = () => (
  <Box
    width="100vw"
    padding="20px"
    background="transparent"
    display="flex"
    flexDirection="column"
    alignItems="center" // Center content horizontally
    position="relative" // Ensure the star is positioned relative to the container
height={'auto'}  >
    {/* Rotating Star in the top-right corner */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top={{base: '10px', md: '10px'}}
      left={{base: '10px', md: '40px'}}
      width="30px" // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite" // Animation to rotate the image
      mr={50}
      mb={40}
    />

    {/* Row with Heading and Text */}
    <Box 
      width="90%" // Match the image width
      display="flex" 
      justifyContent={{ base: 'center', md: 'space-between' }} // Center on small screens, space-between on larger
      alignItems="center"
      flexDirection={{ base: 'column', md: 'row' }} // Stack on small screens
      paddingBottom="20px"
    >
      {/* Left Side: Heading */}
      <Text 
        fontSize={{ base: '3xl', md: '4xl' }} 
        fontWeight="bold" 
        color="white" 
        fontFamily="CabinetGrotesk-Black"
        textAlign={{ base: 'center', md: 'left' }} // Centered on small screens, left-aligned on larger
        width="100%" // Full width to align with the image
        paddingRight={{ md: '10px' }} // Optional: slight right padding on larger screens
      >
Empowering Organizations Through Data Innovation     
 </Text>

      {/* Right Side: Text */}
      <Text 
        fontSize={{ base: 'md', md: 'lg' }} 
        color="white" 
        fontFamily="CabinetGrotesk-Regular"
        textAlign={{ base: 'center', md: 'left' }} // Centered on small screens, left-aligned on larger
        width="100%" // Full width to align with the image
        paddingLeft={{ md: '10px' }} // Optional: slight left padding on larger screens
      >
We are committed to redefine data analytics to help organizations thrive in a data-driven world.      </Text>
    </Box>

    {/* Centered Image */}
    <Image 
      src={image}
      alt="Commitment to AI" 
      width="90%" // Image takes 90% of the width
      objectFit="cover" // Ensures the image covers the box without distortion
      borderRadius="md" // Optional: rounded corners
    />

    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default CommitmentToDeliver;
