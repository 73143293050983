import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={10}>
      <Text fontSize={{ base: 'xl', md: '5xl' }} fontWeight="bold" color="white">
      Our Team Is Here For You, Reach Out Anytime!
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
We’re here to assist you 24/7/365. Whether you have inquiries about our services or need support, we’re just a message away.      </Text>
    </Stack>

    {/* Icons with Heading and Text under them */}
    <Box
      display={{ base: 'block', md: 'flex' }}  // Stack vertically on smaller screens
      justifyContent="center"
      alignItems="flex-start"
      mt={40}
      gap={{ base: 20, md: 200 }} // Adjust gap for smaller screens
    >
      {/* First Icon Container - Location */}
      <Stack textAlign="center" align="center" spacing={5} mx={10} mt={{ base: 20, md: 0 }}> {/* Add mt for mobile */}
        <Box
          bg="transparent"  // Set to transparent
          width="80px"
          height="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="md" // Makes the icon container square
          boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
        >
          <Icon as={InfoOutlineIcon} boxSize="40px" color="white" />
        </Box>
        <Text fontSize="2xl" fontWeight="bold" color="white">
        Address
        </Text>
        <Text fontSize="md" color="white">
        123 Main St, Anytown, USA        </Text>
        {/* <Text fontSize="md" color="white">
          California 208434
        </Text> */}
      </Stack>

      {/* Second Icon Container - Contact */}
      <Stack textAlign="center" align="center" spacing={5} mx={10} mt={{ base: 20, md: 0 }}> {/* Add mt for mobile */}
        <Box
          bg="transparent"
          width="80px"
          height="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
        >
          <Icon as={PhoneIcon} boxSize="40px" color="white" />
        </Box>
        <Text fontSize="2xl" fontWeight="bold" color="white">
        Let’s Talk
        </Text>
        <Text fontSize="md" color="white">
        (123) 456-7890        </Text>
        {/* <Text fontSize="md" color="white">
         +088 (246) 642-27-10
        </Text> */}
      </Stack>

      {/* Third Icon Container - Email */}
      <Stack textAlign="center" align="center" spacing={5} mx={10} mt={{ base: 20, md: 0 }}> {/* Add mt for mobile */}
        <Box
          bg="transparent"
          width="80px"
          height="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
        >
          <Icon as={EmailIcon} boxSize="40px" color="white" />
        </Box>
        <Text fontSize="2xl" fontWeight="bold" color="white">
        Email Us
        </Text>
        <Text fontSize="md" color="white">
        info@incortafederal.com        
        </Text>
      </Stack>
    </Box>
  </Box>
);

export default SectionBreak;
