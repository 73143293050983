import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak2 = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={10}>
      <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
      Step into the Future with Our AI Solutions      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Join us in embracing the future of business with our advanced AI technology. When you partner with us, you're not just using new tools; you're preparing your business for change and growth. Our solutions help you make smart, data-driven decisions, giving you an advantage in your industry. Let’s work together to take your business to new levels with the power of AI!</Text>

    </Stack>


  </Box>
);

export default SectionBreak2;
