import React from 'react';
import { Box, Text, Image, SimpleGrid } from '@chakra-ui/react';
import SectionTitle from '../../../../components/SectionTitle';

const services = [
  {
    title: 'Strong Defense',
    description: 'Designed to protect your data from various threats and breaches, ensuring that you stay one step ahead of potential risks.',
  },
  {
    title: 'Regulatory Compliance',
    description: 'We prioritize your safety by strictly adhering to industry standards and regulations.',
  },
  {
    title: 'Access Management',
    description: 'Guarantee that only authorized personnel can view or modify sensitive information, protecting your organization from threats.',
  },
  {
    title: '24/7 Monitoring',
    description: 'Enjoy peace of mind with our continuous real-time monitoring.',
  },
];

const DecisionMaking = () => {
  return (
    <Box
      width="100%"
      padding={{ base: '20px', md: '50px', lg: '100px' }}
      background="transparent"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {/* Section Heading */}
      {/* <SectionTitle text="Empower Decision-Making with Key Features of Our Platform" position="center" /> */}

      {/* Grid of Service Cards */}
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 2, lg: 4 }}
        spacing={{ base: 5, sm: 6, md: 8, lg: 10 }}
        width="100%"
        maxW="1200px"
        mt={{ base: 10, md: 16 }}
        justifyContent={'center'}
        justifyItems={'center'}
      >
        {services.map((service, index) => (
          <Box
            key={index}
            bg="rgba(128, 128, 128, 0.2)" // Slightly grayish transparent background
            borderRadius="20px"
            p={{ base: 5, sm: 6, md: 5 }} // Increased padding
            width="100%"
            maxW={{ base: '100%', sm: '100%', md: '100%', lg: '500px' }} // Increased maxW for lg screens
            boxShadow="0 10px 30px rgba(0, 0, 0, 0.5)"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{
              transform: 'scale(1.05)',
              boxShadow: '0 20px 40px rgba(0, 0, 0, 0.4)',
            }}
            mx="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            border="1px solid rgba(255, 255, 255, 0.2)" // Subtle border for enhanced visibility
          >

            {/* Service Title */}
            <Text
              fontSize={{ base: 'md', sm: 'lg', md: 'xl' }} // Slightly larger font size
              fontWeight="bold"
              color="white"
              textShadow="0 0 5px rgba(0, 0, 0, 0.7)" // Adds text shadow for better readability
            >
              {service.title}
            </Text>
            <Text
              fontSize={{ base: 'md', sm: 'lg', md: 's' }} // Slightly larger font size
              color="white"
              // noOfLines={2} // Limits to 2 lines and adds ellipsis if overflow
              textShadow="0 0 5px rgba(0, 0, 0, 0.7)" // Adds text shadow for better readability
              mt={8}

            >
              {service.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default DecisionMaking;
