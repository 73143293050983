import { Box, Text, Button, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image from '../../assets/images/14.png';

const Cover = () => (
  <Box
    width="100vw"
    height="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding="20px"
    background="transparent"
    bgImage={image}
    bgSize={{ base: '90%', md: '30%' }} // 90% on smaller screens, 30% on large screens
    bgPosition="center"
    bgRepeat="no-repeat"
    position="relative"
  >
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={1}
    />

    <Stack textAlign="center" spacing={2} zIndex={2}>
      <Text fontSize={{ base: '4xl', md: '7xl' }} fontWeight="bold" color="white">
        Unlock Data-Driven Success
      </Text>
      <Text fontSize={{ base: '3xl', md: '5xl' }} color="white">
        With Next-Gen Analytics Platform & Advanced AI Solutions
      </Text>
      <Text my="20px" fontSize={{ base: 'md', md: '2xl' }} color="white">
        Transform insights into impactful actions.
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={4} justify="center">
        <Link to="/services">
          <Button
            variant="outline"
            fontWeight="normal" // Set font weight to normal to make text thinner
            borderRadius={8}
            borderColor="#A9A9A9"
            color="#A9A9A9"
            fontSize="sm" // Reduces the text size inside the button
            _hover={{ bg: 'whiteAlpha.200' }}
            minWidth="150px" // Further reduced minimum width
          >
            Our Services
          </Button>
        </Link>
        <Link to="/contact">
          <Button
            variant="outline"
            borderRadius={8}
            fontWeight="normal" // Set font weight to normal to make text thinner
            borderColor="#A9A9A9"
            color="#A9A9A9"
            fontSize="sm" // Reduces the text size inside the button
            _hover={{ bg: 'whiteAlpha.200' }}
            minWidth="150px" // Further reduced minimum width
          >
            Get In Touch
          </Button>
        </Link>
      </Stack>
    </Stack>
  </Box>
);

export default Cover;
