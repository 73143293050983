import React from 'react';
import { Box, Text, Grid, Image } from '@chakra-ui/react';

// Placeholder icons – replace with actual icon paths
import icon1 from '../assets/images/icon1.png'; // Replace with actual icons
import icon2 from '../assets/images/icon2.png'; // Example paths, replace them
import icon3 from '../assets/images/icon3.png'; // Example paths, replace them
import icon4 from '../assets/images/icon4.png'; // Example paths, replace them
import icon5 from '../assets/images/icon5.png'; // Example paths, replace them
import icon6 from '../assets/images/icon6.png'; // Example paths, replace them

const SpecialRequestComponent = ({ title, description }) => {
  return (
    <Box
    bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
    boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside      
      borderRadius="15px"
      p={5} // Reduced padding to make the whole component smaller
      width="100%"
      height="400px" // Reduced height
      border="1px solid rgba(255, 255, 255, 0.1)"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      _hover={{
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',
      }}
    >
      {/* Icon Grid */}
      <Box
        mb={0}
        border="1px solid rgba(255, 255, 255, 0.2)" // Border around the grid of icons
        borderRadius="15px"
        width="100%"
        minHeight="200px"
        paddingX={{ base: 4, md: 10 }} // Responsive padding for smaller screens
        paddingY={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          width="100%"
          templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)' }} // Responsive column layout
          gap={2}
          justifyItems="center"
        >
          {/* First row of icons with margin right */}
          <Box gridColumn="1 / -1" mr="20px">
            <Grid templateColumns={{ base: 'repeat(3, 1fr)' }} gap={2} justifyItems="center">
              {[icon1, icon2, icon3].map((icon, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="rgba(255, 255, 255, 0.05)"
                  border="1px solid rgba(255, 255, 255, 0.2)"
                  borderRadius="10px"
                  padding={3}
                  boxShadow="0 3px 6px rgba(0, 0, 0, 0.3)"
                  minW={12}

                >
                  <Image src={icon} alt={`Icon ${index + 1}`} boxSize="30px" />
                </Box>
              ))}
            </Grid>
          </Box>

          {/* Second row of icons with margin left */}
          <Box gridColumn="1 / -1" ml="20px">
            <Grid templateColumns={{ base: 'repeat(3, 1fr)' }} gap={2} justifyItems="center">
              {[icon4, icon5, icon6].map((icon, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="rgba(255, 255, 255, 0.05)"
                  border="1px solid rgba(255, 255, 255, 0.2)"
                  borderRadius="10px"
                  padding={3}
                  boxShadow="0 3px 6px rgba(0, 0, 0, 0.3)"
                  minW={12}
                >
                  <Image src={icon} alt={`Icon ${index + 4}`} boxSize="30px" />
                </Box>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Box>

      {/* Title */}
      <Box  mt={25}>
      <Text color="white" fontWeight="bold" fontSize="lg" mb={3}>
        {title}
      </Text>

      {/* Description */}
      <Text color="gray.300" fontSize="sm">
        {description}
      </Text>
      </Box>
    </Box>
  );
};

export default SpecialRequestComponent;
