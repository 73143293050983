import Cover from './Cover';
import OurProcess from './OurProcess';
import SectionBreak from './SectionBreak';

import WhoWeAre from './WhoWeAre';


import ServicesSection from './OurServices';
import TeamSection from './OurTeam';

const Home = () => (
  <>
  <Cover/>
  <SectionBreak/>
  <OurProcess/>
  <WhoWeAre/>
  <ServicesSection/>
  <TeamSection/>
  </>

);

export default Home;
