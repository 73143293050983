import { Box, Text, Button, Stack, Image } from '@chakra-ui/react';
import backgroundImage from '../../assets/images/14.png';
import starImage from '../../assets/images/image.png'; // Import the small rotating star image
import { Link } from 'react-router-dom';

const WhoWeAre = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    alignItems="center"
    padding="20px"
    background="transparent"
    flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens
    position="relative" // Enables positioning of the rotating star
  >
    {/* Rotating Star Image */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top="10px"
      left="10px"
      width="30px" // Adjust size of the star
      height="30px"
      animation="rotate 5s linear infinite" // Animation to rotate the image clockwise
      mx={20}
      mb={10}
    />

    {/* Container for the Image */}
    <Box
      width={{ base: '100%', md: '48%' }} // Full width on small screens, 50% on medium and up
      height="auto" // Adjust height automatically
      overflow="hidden" // Ensures no overflow of the image
      borderRadius="md" // Optional: rounded corners
      paddingLeft={{ base: '0', md: '50px' }} // No padding on small screens, padding on larger ones

    >
      <Image 
        src={backgroundImage}
        alt="Background" 
        height={{ base: '30%', md: '90%' }} // Adjust height based on screen size
        width="80%" // Full width of the container
        objectFit="cover" // Ensures the image covers the box without distortion
      />
    </Box>

    {/* Container for the Text */}
    <Stack 
      textAlign="left" 
      spacing={2} 
      zIndex={2} 
      width={{ base: '100%', md: '52%' }} // Full width on small screens, 50% on medium and up
      paddingLeft={{ base: '0', md: '0px' }} // No padding on small screens, padding on larger ones
      paddingRight={{ base: '0', md: '100px' }} // No padding on small screens, padding on larger ones
      mt={{ base: '20px', md: '0' }} // Margin top on small screens for separation
    >
      <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
      Delivering Business Excellence With Advanced AI Innovations
      </Text>
      <Text fontSize={{ base: 'md', md: 'l' }} color="white" fontFamily="lato">
      We are leading the AI innovation by dedicating ourselves to helping businesses harness its power to innovate, optimize, and grow. Our experts create end-to-end solutions that are tailored to perfectly match your needs. Our solution stands out by combining a modern data analytics platform with integrated AI/ML. This lets us convert unstructured data like videos, drone footage, or anything stored in a data lake, into valuable insights like none other.
      </Text>
      <Button
        mt={5}
        variant="outline"
        borderColor="white"
        color="white"
        width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on larger ones
        maxWidth={400}
        minHeight={50}
        borderRadius={20}
        _hover={{ bg: 'whiteAlpha.200' }}
        as={Link} // Use Link from react-router-dom
        to="/delivering-business" // Navigate to the new page
        
      >
For More Information      
</Button>
    </Stack>

    {/* Adding keyframes for rotation */}
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default WhoWeAre;
