import React from 'react';
import { Box, Text, SimpleGrid, Image } from '@chakra-ui/react';
import man1 from '../../assets/images/man1.png';  // Example image
import man2 from '../../assets/images/man2.png';  // Example image
import SectionTitle from '../../components/SectionTitle';
import starImage from '../../assets/images/image.png';  // Import the star image

// Dummy team member data
const teamMembers = [
  {
    name: 'Robert Heriford',
    role: 'A seasoned data professional with 20 years of service in the USAF and extensive experience at Oracle in product development, analytics, and sales.',
    image: man1,
  },
  {
    name: 'General Norman Andersson',
    role: 'US Army Retired, with 37 years of dedicated service. Currently the CEO of Veteran Engineering and Technology (VET), General Andersson also serves as our mentor.',
    image: man2,
  },

  
];

// Component
const TeamSection = () => {
  return (
    <Box     
      width="100vw"
      height="auto"  // Set height to auto to adjust based on content
      padding={{ base: "20px", md: "100px" }}  // Responsive padding
      background="transparent"
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // Distributes space between items 
    >
      {/* Rotating Star in the top-left corner */}
      <Box
        as="img"
        src={starImage}
        alt="Rotating Star"
        position="absolute"
        top={{base: '10px', md: '60px'}}
        right={{base: '10px', md: '50px'}}
        width="30px"  // Adjust the size as needed
        height="30px"
        animation="rotate 5s linear infinite"  // Rotating animation
      />
      <Box
        as="img"
        src={starImage}
        alt="Rotating Star"
        position="absolute"
        bottom={{base: '10px', md: '200px'}}
        left={{base: '10px', md: '50px'}}
        width="30px"  // Adjust the size as needed
        height="30px"
        animation="rotate 5s linear infinite"  // Rotating animation
      />
      
      {/* Section Title */}
      <SectionTitle text="Meet Our Founders" position="center" />
      <Text textAlign={'center'} color={'white'} mb={10}>
        Discover brilliance in every role as you meet our exceptional team, the heartbeat of our success.
      </Text>

      {/* Team Member Grid */}
      <SimpleGrid
        mt={10}  // Margin top for the grid
        columns={[1, teamMembers.length === 1 ? 1 : 2,  teamMembers.length < 3 ? teamMembers.length : 3]}  // Responsive: 1 column if only 1 member, 2 for 2 members, and 3 for larger screens
        spacing={10}  // Consistent gap between items
        justifyItems="center"  // Center the items
      >
        {teamMembers.map((member, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
            width="300px"  // Card width
            mt={{ base: 10, md: 20 }}
          >
            {/* Card (the image frame) */}
            {/* <Box
              bg="transparent"  // Glass-like transparent background
              borderBottomWidth="0.2px"
              border="1.48px solid rgba(255, 255, 255, 0.2)"  // Border as per config
              backdropFilter="blur(10px)"  // Apply blur effect for glass effect
              borderRadius="20px"  // Rounded corners
              width="300px"  // Width of the card
              height="350px"  // Fixed height for the card
              position="relative"  // Positioned relative to contain the image
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={1}
              _hover={{
                transform: 'scale(1.05)',  // Slight enlarge effect on hover
                boxShadow: '0 20px 40px rgba(255, 255, 255, 0.5)',  // Enhanced shadow on hover
              }}
              transition="transform 0.3s ease, box-shadow 0.3s ease"
            >
            </Box> */}

            {/* Image positioned outside the top of the card */}
            {/* <Box
              position="absolute"
              top="-52px"  // Move the image upwards for the 3D effect
              width="270px"  // Image width matches the card width
              height="400px"  // Image height
              zIndex={2}  // Ensure the image is above the card
            >
              <Image
                src={member.image}
                alt={member.name}
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </Box> */}

            {/* Team Member Name and Role positioned below the card */}
            <Box
              mt={8}  // Space between the card and text
              textAlign="center"
              width="300px"  // Same width as the card for alignment
            >
              <Text fontSize="lg" fontWeight="bold" color="white">
                {member.name}
              </Text>
              <Text fontSize="sm" mt={2} color="gray.400">
                {member.role}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>

    {/* Rotating Star Animation */}
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
    </Box>
  );
};

export default TeamSection;
