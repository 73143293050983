import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import Team from './pages/OurTeam';
import Header from './components/Header';
import Footer from './components/Footer';
import JoinWaitlist from './components/JoinWaitlist';
import ScrollToTop from './components/ScrollToTop';
import RealTimeAnalytics from './pages/Services/SubServices/RealTime'; 
import SeamlessData from './pages/Services/SubServices/SeamlessDataIntegration'
import UserDrivenReporting from './pages/Services/SubServices/UserDrivenReporting';
import ComprehensiveSecuritySolutions from './pages/Services/SubServices/ComprehensiveSecuritySolutions';
import DeliveringBusiness from './pages/Home/DeliveringBusiness';


import './App.css';  // Import the CSS for particles and other styles
function App() {
  useEffect(() => {
    const createParticle = (e) => {
      const particle = document.createElement('span');
      particle.classList.add('particle');
      document.body.appendChild(particle);

      // Set particle size
      const size = Math.random() * 20 + 20; // Size between 10px and 30px
      particle.style.width = `${size}px`;
      particle.style.height = `${size}px`;

      // Center the particle on the cursor, accounting for vertical scroll
      particle.style.left = `${e.clientX - size / 2}px`;
      particle.style.top = `${e.clientY + window.scrollY - size / 2}px`; // Add window.scrollY

      // Optional: Randomize particle color
      // const colors = ['#ffffff', '#ffcc00', '#00ccff', '#ff6699'];
      // const color = colors[Math.floor(Math.random() * colors.length)];
      // particle.style.backgroundColor = color;

      // Remove particle after animation completes
      setTimeout(() => {
        particle.remove();
      }, 1500); // Match the animation duration (1.5s)
    };

    // Throttle particle creation to improve performance
    let isThrottled = false;
    const handleMouseMove = (e) => {
      if (!isThrottled) {
        createParticle(e);
        isThrottled = true;
        setTimeout(() => {
          isThrottled = false;
        }, 150); // Adjust throttle delay as needed
      }
    };

    // Attach the mousemove event listener to the entire document
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Box className="starry-background">
      <ScrollToTop /> {/* Ensure this is included so the page scrolls to the top on route changes */}
      <Header />
      <Box>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/real-time-analytics" element={<RealTimeAnalytics />} />
          <Route path='/seamless-data-integration' element={<SeamlessData />} />
          <Route path='/user-driven-reporting' element={<UserDrivenReporting />} />
          <Route path='/comprehensive-security-solutions' element={<ComprehensiveSecuritySolutions />} />
          <Route path='/delivering-business' element={<DeliveringBusiness />} />
          

        </Routes>
      </Box>
      <JoinWaitlist />
      <Footer />
    </Box>
  );
}

export default App;
