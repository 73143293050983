import { Box, Text, Grid } from '@chakra-ui/react';
import RequestComponent from '../../components/RequestComponent';
import iconImage1 from '../../assets/images/codeblock.png';  // Replace with actual image paths
import SubscriptionComponent from '../../components/SubscriptionComponent';
import SpecialRequestComponent from '../../components/specialRequestComponent';
import TestAndOptimizeComponent from '../../components/TestSectionComponent';
import IndustryLeaderComponent from '../../components/IndustryComponent';
import SectionTitle from '../../components/SectionTitle';

const OurProcess = () => (
  
  <Box
    width="100vw"
    height="auto"
    padding={{ base: "20px", md: "80px" }}
    background="transparent" // Keep black background for the section
    position="relative"
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
  <SectionTitle text="Our Streamlined Processes For Data Success!" position="left" />
        

    
    {/* First Row: 3 Cards */}
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} 
      gap={8}
      width={{ base: '100%', md: '80%' }}
    >
      <SubscriptionComponent />

      <SpecialRequestComponent
        title="02. Comprehensive Data Collection"
        description="Gather ALL your data for a complete view and drill down to the lowest level of detail."
        />
      
      <RequestComponent
        title="03. Empower Business Users"
        description="Reduce the burden on IT by enabling business users to create analytics with sub-second responses."
        image={iconImage1} // Add image here
      />
    </Grid>

    {/* Second Row: 2 Cards */}
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} 
      gap={8}
      mt={10}
      width={{ base: '100%', md: '80%' }}
    >
     <TestAndOptimizeComponent
  title="04. Innovative Solutions"
  description="Unlike traditional platforms, Incorta eliminates the need for ETL processes and complex data modeling."
/>
<IndustryLeaderComponent
  title="05. Become An Industry Leader"
  description="Continue requesting as many workflow-automations and AI-applications as you wish, and transform your organisation into a worldwide industry leader."
/>
    </Grid>
  </Box>
);

export default OurProcess;
