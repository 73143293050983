import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak2 = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={10}>
      <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
      Turn Insights into Action for Business Growth
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Make the most of your insights to drive your business forward. Our Real-Time Data Analytics helps you not only monitor your performance but also predict future trends. By looking at past data along with live updates, you can spot patterns and find new opportunities for growth. </Text>
<Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Stay ahead of your competition by using predictive analytics to anticipate market changes, use your resources wisely, and improve customer experiences. With our platform, you’re not just responding to what’s happening now; you’re actively shaping your future. </Text>

    </Stack>


  </Box>
);

export default SectionBreak2;
