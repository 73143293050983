
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'CabinetGrotesk', sans-serif`, 
    body: `'CabinetGrotesk', sans-serif`,  // Default body font
    lato: `'Lato', sans-serif`, // Additional Lato font
  },
  fontWeights: {
    thin: 100,
    normal: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
});

export default theme;
