import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak2 = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={10}>
      <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
      Seamless Integration & Scalability
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Our User-Driven Reporting Tools work smoothly with your current systems, making it easy to access real-time data. Whether you run a small business or a large company, our tools can grow with you. Users can customize dashboards, automate reports, and share insights with their teams, improving transparency and teamwork. As your business expands, our tools will adapt to meet your changing data needs, helping you stay competitive. </Text>
    </Stack>


  </Box>
);

export default SectionBreak2;
