import { Box, Text, Button, Stack } from '@chakra-ui/react';
import image from '../../assets/images/Rectangle-2.png';

const Cover = () => (
  <Box
  width="100vw"
  height="100vh"
  display="flex"
  justifyContent="center"
  alignItems="center"
  padding="20px"
  background="transparent"
  bgImage={image}
  bgSize={{ base: '90%', md: '30%' }} // 90% on smaller screens, 30% on large screens
  bgPosition="center"
  bgRepeat="no-repeat"
  position="relative"
  >
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={1}
    />

    <Stack textAlign="center" spacing={2} zIndex={2}>
    <Text fontSize={{ base: '4xl', md: '7xl' }} fontWeight="bold" color="white">
    Contact Us
      </Text>
      <Text mt="20px" fontSize={{ base: 'md', md: '2xl' }} color="white">
We’re here to help you with any questions or support you need.      </Text>
      </Stack>
  </Box>
);

export default Cover;
