import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={5}>
    <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
    Unlock Insights with User-Driven Reporting Tools
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Our User-Driven Reporting Tools will allow your team to create your own reports and analyze data without needing help from IT. This means your employees can easily explore data and respond to changes in the market. By letting them work independently, you boost efficiency and encourage new ideas. Our services will help you make better decisions. </Text>      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
        </Text>
    </Stack>


  </Box>
);

export default SectionBreak;
