import { Box, Text, Stack } from '@chakra-ui/react';
import starImage from '../../assets/images/image.png';  // Make sure to import the star image

const WhyChooseUs = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding="40px"
    background="transparent"
    position="relative"  // Make the container relative so the star can be positioned inside it
  >
    {/* Rotating Star in the top-right corner */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top={{base: '10px', md: '60px'}}
      right={{base: '10px', md: '50px'}}
      width="30px"  // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite"  // Animation to rotate the image
    />

    {/* Text Content */}
    <Stack textAlign="center" spacing={2}>
      <Text fontSize={{ base: 'xl', md: '5xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
      We’re Here To Support Your Data Needs
      </Text>
      <Text fontSize={{ base: 'm', md: 'xl' }} fontWeight="normal" color="white" fontFamily="CabinetGrotesk-Black">
      Our solutions make data analysis easier, improve decision-making, and empower users at every level.      </Text>
    </Stack>

    {/* Rotating Star Animation */}
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default WhyChooseUs;
