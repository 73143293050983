import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={5}>
      <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
      Drive Excellence & Innovation with AI Solutions      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
We are dedicated to helping businesses make the most of artificial intelligence. Our goal is to help organizations grow, improve, and innovate using advanced AI solutions. Our team works closely with you to create customized solutions that fit your needs. By combining a modern data analytics platform with AI and machine learning, we turn unstructured data into useful insights, helping your business move forward.
</Text>      
<Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
        </Text>
    </Stack>


  </Box>
);

export default SectionBreak;
