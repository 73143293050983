import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { AddIcon, CheckIcon, StarIcon, InfoIcon } from '@chakra-ui/icons'; // Import dummy icons
import RequestComponent from '../../components/SubscriptionComponent';
import starImage from '../../assets/images/image.png'; // Adjust the path to your local image
import { Link } from 'react-router-dom';

const InnerCard = ({ icon, heading, text }) => (
  <Box
    flexGrow={1} // Allow cards to grow equally in height
    width="100%"
    padding="16px"
    bg="transparent"
    display="flex"
    flexDirection="column"
    justifyContent="flex-start" // Ensure the content starts at the top
    boxShadow="none" // No shadow to keep it transparent
    my={10}
    borderLeft="1px solid gray" // Add a left border
    minHeight="270px" // Set a minimum height to ensure consistency
  >
    <Box
      width="40px" // Set the width and height for the square box
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="2px solid gray" // Border for the box
      borderRadius="md"
      boxShadow="inset 0 0 10px rgba(255, 255, 255, 0.2)" // Inner shadow effect
      mb={3} // Space between icon box and heading
    >
      {icon}
    </Box>
    <Text fontSize="lg" fontWeight="bold" color="white" mt={2} textAlign="left">{heading}</Text>
    <Text fontSize="md" color="gray.300" textAlign="left">{text}</Text>
  </Box>
);

const OurFoundationalValues = () => (
  <Box
    width="100vw"
    height="auto" // Full height to center vertically
    display="flex"
    alignItems="center"
    justifyContent="center"
    background="transparent"
    padding={{ base: "10px", md: "0px" }} // Responsive padding
    position="relative" // Ensure the star is positioned relative to this container
  >
    {/* Rotating Star in the top-left corner */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top={{ base: '10px', md: '10px' }}
      left={{ base: '10px', md: '50px' }}
      width="30px" // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite" // Animation to rotate the image
    />

    <Box
      width={{ base: "90%", md: "80%" }} // Adjust to 90% on smaller screens
      height="auto"
      padding={{ base: "20px", md: "0px" }}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
      boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
      backdropFilter="blur(10px)" // Glass effect with blur
      borderRadius={20}
    >
      <Flex
        width="100%"
        direction={{ base: "column", md: "row" }} // Stack vertically on smaller screens
        justifyContent="space-between"
        alignItems="stretch" // Stretch to make all cards the same height
      >
        <Flex
          width={{ base: "100%", md: "40%" }} // Full width on smaller screens
          direction="column"
          alignItems="center"
          justifyContent={'center'}
          mb={{ base: 10, md: 0 }} // Margin bottom for smaller screens
        >
          <Box p={20} mb={10}>
            <Text fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" color="white">
              Innovative Solutions for Modern Data Challenges
            </Text>
            <Text fontSize={{ base: "md", md: "lg" }} color="gray.400">
              We offer an array of solutions that are perfect to tackle modern data challenges. Whether it’s structured and unstructured data, our platform can seamlessly integrate and also eliminate the complexities of traditional processes.
            </Text>
            <Link to="/contact">
            <Button
              mt={4}
              colorScheme="teal"
              bg="transparent"
              border="2px solid white" // White border
              color="white" // Text color
              _hover={{
                bg: 'rgba(255, 255, 255, 0.1)', // Slightly change background on hover
                boxShadow: 'inset 0 0 10px white' // Inner glow effect on hover
              }}
              _focus={{
                boxShadow: 'inset 0 0 10px white' // Inner glow effect on focus
              }}
              _active={{
                boxShadow: 'inset 0 0 10px white' // Inner glow effect on active
              }}
            >
              Get In Touch
            </Button>
            </Link>
          </Box>
        </Flex>

        <Flex width={{ base: "100%", md: "30%" }} direction="column" justifyContent={'center'} spacing={4}>
          <InnerCard
            icon={<AddIcon boxSize={6} color="white" />} // Dummy icon
            heading="Cutting-Edge Technology"
            text="Our platform integrates both structured and unstructured data seamlessly, allowing organizations to harness the full potential of their data without the complications of traditional ETL processes."
          />
          <InnerCard
            icon={<CheckIcon boxSize={6} color="white" />} // Dummy icon
            heading="Rapid Data Ingestion"
            text="With the ability to ingest hundreds of millions of rows per hour, Incorta ensures you have real-time access to the data you need for timely decision-making."
          />
        </Flex>

        <Flex width={{ base: "100%", md: "30%" }} direction="column" justifyContent={'center'} spacing={4}>
          <InnerCard
            icon={<StarIcon boxSize={6} color="white" />} // Dummy icon
            heading="Empowered Business Users"
            text="We empower business users to create their own analytics, significantly reducing reliance on IT departments and enabling quicker, data-driven decisions across the organization."
          />
          <InnerCard
            icon={<InfoIcon boxSize={6} color="white" />} // Dummy icon
            heading="Leadership with Experience"
            text="Our leadership team, including seasoned professionals like Robert Heriford and General Norman Andersson, brings decades of experience in data analytics, military service, and technology development to drive our mission forward."
          />
        </Flex>

      </Flex>

      <style>
        {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Box>
  </Box>
);

export default OurFoundationalValues;
