import { Box, Text, Stack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, InfoOutlineIcon } from '@chakra-ui/icons'; // Import Chakra UI icons

const SectionBreak2 = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bg="gray.100"
    padding="20px"
    background="transparent"
    position="relative"
    flexDirection="column"
  >
    <Stack textAlign="center" spacing={5} mt={10}>
      <Text fontSize={{ base: 'xl', md: '6xl' }} fontWeight="bold" color="white">
      Achieve a Unified Data Ecosystem      </Text>
      <Text
        fontSize={{ base: 'md', md: 'xl' }}
        mt={6}
        fontWeight="normal"
        color="white"
        maxW={{ base: '100%', md: '80%' }}
        mx="auto"
      >
Make the most of your data by bringing everything together in one place. Our integration solution connects different data sources, creating a smooth flow of information. This helps teams work better together and make smarter decisions. With our powerful analytics tools, you’ll gain valuable insights that drive innovation and keep you ahead of market changes. Embrace a unified data environment that transforms how you use information across your organization.</Text>

    </Stack>


  </Box>
);

export default SectionBreak2;
