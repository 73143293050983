import Cover from './Cover';
import GetInTouch from './GetInTouch';
import SectionBreak from './SectionBreak';
const Home = () => (
  <>
  <Cover/>
  <SectionBreak/>
  <GetInTouch/>
  </>

);

export default Home;
