import { Box, Text, Stack, Image } from '@chakra-ui/react';
import image from '../../assets/images/aipowerimage.png';

// Array of objects containing both heading and paragraph for each card
const cardContent = [
  {
    title: '1. Quick Setup',
    paragraph: 'Sign up on our platform in just a few minutes. Getting started with our AI services is easy!',
  },
  {
    title: '2. Set Your Goals',
    paragraph: 'Let us know what you want to achieve, like improving decision-making or gaining valuable insights.',
  },
  {
    title: '3. Real-Time Insights',
    paragraph: 'Once your data is integrated, you can start using our AI tools to track performance in real time and make adjustments to get the desired results.',
  },
];

const HowItWorks = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    flexDirection="column"
    alignItems="center"
    padding={{ base: '30px', md: '50px' }} // Responsive padding
    background="transparent"
    m={0}
  >
    {/* Top Text Section */}
    <Stack
      spacing={4}
      textAlign="center"
      mb={10} // Margin bottom to create space between text and content below
      maxW="800px" // Max width to keep text manageable
      width="100%"
      px={5} // Add some padding for smaller screens
    >
      <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
        How It Works
      </Text>
      <Text fontSize={{ base: 'md', md: 'lg' }} color="white" fontFamily="CabinetGrotesk-Regular">
        Simplifying data solutions with innovative AI solutions
      </Text>
    </Stack>

    {/* Main Content Section with Image and Cards */}
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens
    >
      {/* Container for the Image */}
      <Box
        width={{ base: '100%', md: '45%' }} // Full width on small screens, 45% on medium and up
        height="auto" // Adjust height automatically
        overflow="hidden" // Ensures no overflow of the image
        borderRadius="md" // Optional: rounded corners
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={image}
          alt="Background"
          height={{ base: '30%', md: '90%' }} // Adjust height based on screen size
          width="80%" // Full width of the container
          objectFit="cover" // Ensures the image covers the box without distortion
        />
      </Box>

      {/* Container for the Cards */}
      <Stack
        spacing={5}
        width={{ base: '100%', md: '55%' }} // Full width on small screens, 55% on medium and up
        paddingLeft={{ base: 0, md: 50 }} // No padding on small screens, padding on larger ones
        mt={{ base: 20, md: 0 }} // Margin top on small screens for separation
      >
        {cardContent.map((item, index) => (
          <Box
            key={index}
            bg="rgba(128, 128, 128, 0.1)" // Slightly grayish transparent background
            boxShadow="inset 0 0 10px 4px rgba(255, 255, 255, 0.3)"  // Glowing white shadow effect inside
            borderRadius={15}
            p={4} // Responsive padding
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            height="auto" // Adjust height automatically based on content
            width="100%" // Full width of the container
            maxW="600px" // Maximum width for each card
            mx="auto" // Center each card horizontally
            backdropFilter="blur(10px)" // Glass effect with blur
            _hover={{
              boxShadow: 'inset 0 0 15px rgba(255, 255, 255, 0.7)', // Enhanced glow on hover
            }}
          >
            {/* Card Heading */}
            <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold" color="white">
              {item.title}
            </Text>

            {/* Card Paragraph */}
            <Text fontSize={{ base: 'sm', md: 'md' }} color="white" mt={2}>
              {item.paragraph}
            </Text>
          </Box>
        ))}
      </Stack>
    </Box>
  </Box>
);

export default HowItWorks;
